import React, { useState } from 'react';
import './ForgotPassword.css';
import { useHistory } from 'react-router-dom';

const ForgotPassowrd = () => {
    const history = useHistory();
    const [email, setEmail] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        // Mocking forgot password logic, replace with actual forgot password logic
        alert(`Password reset link sent to ${email}`);
        history.push('/login'); // Redirect to login page after handling reset password
    };

    return (
        <div className="forgot-password-container">
            <div className="forgot-password-form">
                <h2>Forgot Password</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Email</label>
                        <input
                            type="email"
                            placeholder="Enter your email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            required
                        />
                    </div>
                    <button type="submit" className="reset-button">Reset Password</button>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassowrd;