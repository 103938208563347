import React, { useState, useContext } from 'react';
import './Login.css';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Oval } from 'react-loader-spinner'; 
import { UserContext } from './context/UserContext';

const Login = ({ onLogin }) => {
    const history = useHistory();
    const [users, SetUser] = useState('');
    const [mdp, SetMdp] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); 
    const { login } = useContext(UserContext);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true); 

        try {
            const response = await axios.post('/api/auth/login', { email: users, password: mdp });

            if (response.status === 200) {
                const { token, user } = response.data;
                localStorage.setItem('token', token); 
                onLogin(token, user);
                login(user, token);
                history.push('/');
                console.log('User ID:', user.id);
                console.log('Username:', user.username);
            } else {
                throw new Error(response.data.message || 'Invalid username/email or password.');
            }
        } catch (error) {
            console.error('Login error:', error);
            setError(error.message || 'An error occurred. Please try again later.');
            toast.error(error.message || 'An error occurred. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='login-container'>
            {/*Header*/}
            <div className="login-head">
                <h2>Pitcheo</h2>
            </div>

            {/*Body*/}
            <div className="login-body">
                <h3>Login</h3>
                <span>Enter your email to Sign in</span>
                <form onSubmit={handleSubmit}>
                    <div className="field">
                        <input
                            type="text"
                            placeholder='email@domain.com'
                            onChange={(e) => SetUser(e.target.value)}
                            value={users}
                            required />
                    </div>
                    <div className="field">
                        <input
                            type="password"
                            placeholder='password'
                            onChange={(e) => SetMdp(e.target.value)}
                            value={mdp}
                            required />
                    </div>
                    <div className='field'>
                        <button type="submit" className='btn'>Sign in</button>
                    </div>
                </form>

                <div className="form-footer">
                    <div className="signup-link">
                        <span>Don't have an account? <Link to="/inscription">Sign Up</Link></span>
                    </div>
                    <div className="forgot-password-link">
                        <span><Link to="/reset-password">Forgot Password?</Link></span>
                    </div>
                </div>

                <span>Or continue with</span>
                <div className='btn-google'>
                    <button>
                        <img src="/assets/img/google.svg" alt="google" />
                        Google
                    </button>
                </div>
            </div>

            {/*Footer*/}
            <div className="login-footer">
                <span className='grey'>By clicking continue, you agree to our
                    <span className='black'><a href=""> Terms of Service </a></span>
                    and
                    <span className='black'><a href="#"> Privacy Policy</a></span>
                </span>
            </div>

            {/* Loading Overlay */}
            {loading && (
                <div className="loading-overlay">
                    <div className="loading-spinner">
                        <Oval
                            height={100}
                            width={100}
                            color="#4fa94d"
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#4fa94d"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        />
                    </div>
                </div>
            )}

            {/* Toast Container */}
            <ToastContainer />
        </div>
    )
}

export default Login;
