import React, { useState } from 'react';
import './Inscription.css';
import { useHistory, Link } from 'react-router-dom';
import { 
    Box, 
    TextField, 
    MenuItem, 
    Select, 
    InputLabel, 
    FormControl, 
    Button, 
    Alert,
    CircularProgress,
    Snackbar
} from "@mui/material";
import PhoneTextField from "mui-phone-textfield";
import axios from 'axios';

const Inscription = ({ onSignUp }) => {
    const [username, setUsername] = useState("");
    const [phoneNumber, setPhoneNumber] = useState({ value: "", country: "FR" });
    const [month, setMonth] = useState('');
    const [day, setDay] = useState('');
    const [year, setYear] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });
    const [isLoading, setIsLoading] = useState(false);
    const [phoneError, setPhoneError] = useState('');
    const history = useHistory();

    const showNotificationWithDelay = (message, severity) => {
        setNotification({ open: true, message, severity });
        return new Promise(resolve => setTimeout(resolve, 2000));
    };

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handleMonthChange = (event) => {
        setMonth(event.target.value);
    };

    const handleDayChange = (event) => {
        setDay(event.target.value);
    };

    const handleYearChange = (event) => {
        setYear(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handlePhoneChange = (phone, country) => {
        if (validatePhoneNumber(phone) || phone === '') {
            setPhoneNumber({ value: phone, country: country });
            setPhoneError('');
        } else {
            setPhoneError('Numéro de téléphone invalide');
        }
    };

    const validatePhoneNumber = (phone) => {
        const phoneRegex = /^[+\d]*$/;
        return phoneRegex.test(phone);
    };

    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setNotification({ ...notification, open: false });
    };

    const handleSignup = async () => {
        setIsLoading(true);
        const signupData = { 
            username,
            email, 
            password, 
            phoneNumber: phoneNumber.value,  
            country: phoneNumber.country,    
            month: parseInt(month, 10),
            day: parseInt(day, 10),
            year: parseInt(year, 10)
        };
    
        console.log('Données à envoyer:', JSON.stringify(signupData, null, 2));
    
        try {
            const response = await axios.post('/api/auth/signup', signupData);
            console.log('Réponse du serveur:', response.data);
            await showNotificationWithDelay('Inscription réussie !', 'success'); 
            onSignUp();
            history.push('/login', { email: email });
        } catch (error) {
            console.error('Erreur lors de l\'inscription:', error.response?.data || error);
            await showNotificationWithDelay(
                error.response?.data?.message || 'Une erreur est survenue lors de l\'inscription',
                'error'
            );
        } finally {
            setIsLoading(false);
        }
    };

    const months = Array.from({ length: 12 }, (_, i) => i + 1);
    const days = Array.from({ length: 31 }, (_, i) => i + 1);
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 100 }, (_, i) => currentYear - i);

    return (
        <div className='inscription-container'>
            <div className="inscription-head">
                <h2>Sign up</h2>
            </div>

            <div className="inscription-body">
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className="confirm">
                    <TextField
                        label="Username"
                        variant="outlined"
                        value={username}
                        onChange={handleUsernameChange}
                        fullWidth
                        margin="normal"
                    />
                    <Box display="flex" justifyContent="space-between" width="100%" marginBottom="20px">
                        <FormControl variant="outlined" style={{ width: '30%' }}>
                            <InputLabel>Month</InputLabel>
                            <Select value={month} onChange={handleMonthChange} label="Month">
                                {months.map((m) => (
                                    <MenuItem key={m} value={m}>{m}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" style={{ width: '30%' }}>
                            <InputLabel>Day</InputLabel>
                            <Select value={day} onChange={handleDayChange} label="Day">
                                {days.map((d) => (
                                    <MenuItem key={d} value={d}>{d}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" style={{ width: '30%' }}>
                            <InputLabel>Year</InputLabel>
                            <Select value={year} onChange={handleYearChange} label="Year">
                                {years.map((y) => (
                                    <MenuItem key={y} value={y}>{y}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    
                    <PhoneTextField
                        country={phoneNumber.country}
                        onCountryChange={(country) => handlePhoneChange(phoneNumber.value, country)}
                        onPhoneNumber={(phone) => handlePhoneChange(phone, phoneNumber.country)}
                        value={phoneNumber.value}
                        label="Phone number(xx xx xxx xx)"
                        fullWidth
                        margin="normal"
                        error={!!phoneError}
                        helperText={phoneError}
                    />
                    
                    <TextField
                        label="Email"
                        variant="outlined"
                        value={email}
                        onChange={handleEmailChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Password"
                        variant="outlined"
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                        fullWidth
                        margin="normal"
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSignup}
                        type="button"
                        fullWidth
                        disabled={isLoading}

                    >
                         {isLoading ? <CircularProgress size={24} /> : 'Sign Up'}
                    </Button>
                </Box>
            </div>

            <div className="inscription-footer">
                Already have an account? <Link to="/login">Log in</Link>
            </div>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={notification.open}
                autoHideDuration={6000}
                onClose={handleCloseNotification}
            >
                <Alert onClose={handleCloseNotification} severity={notification.severity} sx={{ width: '100%' }}>
                    {notification.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default Inscription;
