import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom'; 
import './Create.css';
import { UserContext } from './context/UserContext';

const Create = () => {
  const [videoURL, setVideoURL] = useState('');
  const [caption, setCaption] = useState('');
  const [videoFile, setVideoFile] = useState(null);
  const history = useHistory(); 
  const { user, token } = useContext(UserContext); // Access user info and token from context

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    const objectURL = URL.createObjectURL(file);
    setVideoURL(objectURL);
    setVideoFile(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const formData = new FormData();
    formData.append('file', videoFile);
    formData.append('description', caption);
  
    try {
      const response = await fetch('/api/videos/post', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}` // Send token in header
        },
        body: formData,
      });
  
      if (response.ok) {
        console.log('Video uploaded successfully');
        history.push('/'); 
      } else {
        const errorData = await response.json();
        console.error('Error uploading video:', errorData);
      }
    } catch (error) {
      console.error('Error sending request:', error);
    }
  };

  return (
    <div className='create-container'>
      <div className="video-preview">
        {videoURL ? <video src={videoURL} controls /> : <p>No video selected</p>}
      </div>
      <div className="upload-section">
        <input type="file" accept="video/*" onChange={handleVideoChange} />
        <textarea placeholder="Add a caption..." rows="3" value={caption} onChange={(event) => setCaption(event.target.value)}></textarea>
        <button onClick={handleSubmit}>Post</button>
      </div>
    </div>
  );
}

export default Create;
