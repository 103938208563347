import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faBell, faInbox, fa7, faUser, faPlus } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

function BottomNavbar() {
  return (
    <div className="bottom-navbar">
      <div className="nav-item">
        <NavLink to="/">
          <FontAwesomeIcon icon={faHouse} className="icon active" />
          <span className="item-name active">Home</span>
        </NavLink>
      </div>
      <div className="nav-item">
        <NavLink to="/friend">
        <FontAwesomeIcon icon={fa7} className="notif" />
        <FontAwesomeIcon icon={faBell} className="icon"/>
          <span className="item-name">Notifications</span>
        </NavLink>
      </div>
      <div className="nav-item">
        <NavLink to="/create">
        <FontAwesomeIcon icon={faPlus} className="icon"/>
          <span className="item-name">Create</span>
        </NavLink>
      </div>
      <div className="nav-item">
        <NavLink to="/inbox">
          <FontAwesomeIcon icon={fa7} className="notification" />
          <FontAwesomeIcon icon={faInbox} className="icon" />
          <span className="item-name">Inbox</span>
        </NavLink>
      </div>
      <div className="nav-item">
        <NavLink to={'/profile'}>
          <FontAwesomeIcon icon={faUser} className="icon" />
          <span className="item-name">Profile</span>
        </NavLink>
      </div>
    </div>
  )
}

export default BottomNavbar;
