import React, { useRef, useState, useEffect, useCallback } from 'react';
import FooterLeft from './FooterLeft';
import FooterRight from './FooterRight';
import './VideoCard.css';

const VideoCard = ({ video, setVideoRef, autoplay }) => {
  const { url, username, description, likes, shares, comments, saves, profilePic } = video;
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(!autoplay);
  const [isPlaying, setIsPlaying] = useState(autoplay);
  const [videoError, setVideoError] = useState(null);

  useEffect(() => {
    if (videoRef.current) {
      setVideoRef(videoRef.current);
    }
  }, [setVideoRef]);

  const handleUserInteraction = useCallback(() => {
    if (videoRef.current) {
      setIsMuted(false);
      videoRef.current.muted = false;
    }
  }, []);

  const onVideoPress = useCallback(() => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play().then(() => {
          setIsPlaying(true);
        }).catch((error) => {
          console.error('Play failed:', error);
          setIsPlaying(false);
        });
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.8,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (videoRef.current) {
          if (entry.isIntersecting) {
            videoRef.current.play().then(() => {
              setIsPlaying(true);
              setIsMuted(false);
            }).catch(error => console.error("Error playing video:", error));
          } else {
            videoRef.current.pause();
            setIsPlaying(false);
            setIsMuted(true);
          }
        }
      });
    }, options);

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <div className="video" onClick={handleUserInteraction}>
      {videoError ? (
        <div className="video-error">{videoError}</div>
      ) : (
        <video
          className="player"
          onClick={onVideoPress}
          ref={videoRef}
          loop
          src={url}
          playsInline
          muted={isMuted}
          onError={(e) => {
            console.error("Video loading error:", e);
            setVideoError("Failed to load video");
          }}
          onLoadedData={() => {
            console.log("Video loaded successfully:", url);
          }}
        ></video>
      )}

      <div className="bottom-controls">
        <div className="footer-left">
          <FooterLeft username={username} description={description} />
        </div>
        <div className="footer-right">
          <FooterRight 
            likes={likes} 
            shares={shares} 
            comments={comments} 
            saves={saves} 
            profilePic={profilePic} 
            videoUsername={username} 
          />
        </div>
      </div>
    </div>
  );
};

export default VideoCard;