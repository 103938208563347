import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import VideoCard from './components/VideoCard';
import BottomNavbar from './components/BottomNavbar';
import TopNavbar from './components/TopNavbar';
import Create from './components/Create';
import Inbox from './components/Inbox';
import Profile from './components/Profile';
import NotificationPage from './components/NotificationPage';
import FriendProfile from './components/FriendProfile';
import Inscription from './components/Inscription';
import Login from './components/Login';
import ForgotPassowrd from './components/ForgotPassowrd';
import { useAuth } from './useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { login } from './api';

/*const videoUrls = [
  {
    url: require('./videos/presentation.mp4'),
    profilePic: 'https://p16-sign-useast2a.tiktokcdn.com/tos-useast2a-avt-0068-giso/9d429ac49d6d18de6ebd2a3fb1f39269~c5_100x100.jpeg?x-expires=1688479200&x-signature=pjH5pwSS8Sg1dJqbB1GdCLXH6ew%3D',
    username: 'csjackie',
    description: 'Pitcheo #restaurant #Partenariat ',
    song: 'Original sound - Famed Flames',
    likes: 430,
    comments: 13,
    saves: 23,
    shares: 1,
    id: 1
  },
  {
    url: require('./videos/Essentiel.mp4'),
    profilePic: 'https://p16-sign-useast2a.tiktokcdn.com/tos-useast2a-avt-0068-giso/9d429ac49d6d18de6ebd2a3fb1f39269~c5_100x100.jpeg?x-expires=1688479200&x-signature=pjH5pwSS8Sg1dJqbB1GdCLXH6ew%3D',
    username: 'csjackie',
    description: 'France nvm #Essentiel restaurant #restaurant ',
    song: 'Original sound - Famed Flames',
    likes: 430,
    comments: 13,
    saves: 23,
    shares: 1,
    id: 1
  },
  {
    url: require('./videos/cocotteAmbiance.mp4'),
    profilePic: 'https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/eace3ee69abac57c39178451800db9d5~c5_100x100.jpeg?x-expires=1688479200&x-signature=wAkVmwL7lej15%2B16ypSWQOqTP8s%3D',
    username: 'dailydotdev',
    description: 'Every developer brain @francesco.ciulla #developerjokes #programming #programminghumor #programmingmemes',
    song: 'tarawarolin wants you to know this isnt my sound - Chaplain J Rob',
    likes: '13.4K',
    comments: 3121,
    saves: 254,
    shares: 420,
    id: 2
  },
  {
    url: require('./videos/merveilleuxFred.mp4'),
    profilePic: 'https://p77-sign-va.tiktokcdn.com/tos-maliva-avt-0068/4e6698b235eadcd5d989a665704daf68~c5_100x100.jpeg?x-expires=1688479200&x-signature=wkwHDKfNuIDqIVHNm29%2FRf40R3w%3D',
    username: 'wojciechtrefon',
    description: '#merveilleux #Fred #cake ',
    song: 'help so many people are using my sound - Ezra',
    likes: 5438,
    comments: 238,
    saves: 12,
    shares: 117,
    id: 3
  },
  {
    url: require('./videos/salledesport.mp4'),
    profilePic: 'https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/4bda52cf3ad31c728153859262c329db~c5_100x100.jpeg?x-expires=1688486400&x-signature=ssUbbCpZFJj6uj33D%2BgtcqxMvgQ%3D',
    username: 'faruktutkus',
    description: 'Wait for the end | Daily |  ',
    song: 'Salle de sport',
    likes: 9689,
    comments: 230,
    saves: 1037,
    shares: 967,
    id: 4
  },
];
*/
function App() {
  const [videos, setVideos] = useState([]);
  const videoRefs = useRef([]);
  const { isAuthenticated, user, login, logout } = useAuth();
  const [showVideos, setShowVideos] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [token, setToken] = useState(null);

  const handleLogin = async (username, password) => {
    try {
      const response = await login({ username, password });
      const authToken = response.data.token;
      setToken(authToken);
      // You may want to store token in localStorage or session storage for persistence
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get('/api/videos');
        setVideos(response.data);
        console.log('Fetched videos:', response.data);
      } catch (error) {
        console.error('Error fetching videos:', error);
        setError('Failed to load videos. Please try again later.');
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVideos();
  }, []);

  const handleVideoError = (event) => {
    console.error('Video loading error:', event);
    setError('Error loading video. Please try again later.');
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, []);

  useEffect(() => {
    setVideos(videos);
  }, []);
  useEffect(() => {
    const handleFullScreen = () => {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen().catch((err) => {
          console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
        });
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen().catch((err) => {
          console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
        });
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen().catch((err) => {
          console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
        });
      }
    };

    handleFullScreen();
  }, []);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.8,
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        const videoElement = entry.target;

        if (entry.isIntersecting) {
          videoElement.play().catch(error => console.error("Error playing video:", error));
          videoElement.muted = false;
        } else {
          videoElement.pause();
          videoElement.muted = true;
        }
      });

      const playingVideos = videoRefs.current.filter(
        (video) => !video.paused && !video.muted
      );
      if (playingVideos.length > 1) {
        playingVideos.slice(1).forEach((video) => {
          video.pause();
          video.muted = true;
        });
      }
    };

    const observer = new IntersectionObserver(handleIntersection, observerOptions);

    videoRefs.current.forEach((videoRef) => {
      if (videoRef) observer.observe(videoRef);
    });

    return () => {
      observer.disconnect();
    };
  }, [videos]);

  const handleVideoRef = (index) => (ref) => {
    videoRefs.current[index] = ref;
  };

  const VideoFeed = () => {

    if (!Array.isArray(videos)) {
      console.error('Expected `videos` to be an array, but got:', videos);
      return <div>Error loading videos.</div>;
    }

    return (
      <div className="video-feed">
        {videos.map((video, index) => (
          <VideoCard
            key={video.id}
            video={video}
            username={video.username}
            description={video.description}
            //song={video.song}
            likes={video.likes}
            saves={video.saves}
            comments={video.comments}
            shares={video.shares}
            url={`http://localhost:3001/videos/${video.url}`}
            profilePic={video.profilePic}
            setVideoRef={handleVideoRef(index)}
            autoplay={index === 0}
            onError={handleVideoError}
          />
        ))}
      </div>
    );
  };


  const Home = () => {
    const handlePlayClick = () => {
      setShowVideos(true);
    };

    if (!showVideos) {
      return (
        <div className="home-container" onClick={handlePlayClick}>
          <FontAwesomeIcon icon={faCirclePlay} bounce style={{ color: "#ff9d9d", fontSize: "5rem" }} />
        </div>
      );
    }

    return <VideoFeed />;
  };
  /*
  const Home = () => {
    const [isLoadingVideos, setIsLoadingVideos] = useState(false);
  
    const handlePlayClick = async () => {
      setIsLoadingVideos(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('http://localhost:3000/api/feeds', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setVideos(response.data);
        setShowVideos(true);
      } catch (error) {
        console.error('Error fetching videos:', error);
        setError('Failed to load videos. Please try again later.');
      } finally {
        setIsLoadingVideos(false);
      }
    };
  
    if (isLoadingVideos) {
      return <div>Loading videos...</div>;
    }
  
    if (!showVideos) {
      return (
        <div className="home-container" onClick={handlePlayClick}>
          <FontAwesomeIcon icon={faCirclePlay} bounce style={{color: "#ff9d9d", fontSize: "5rem"}} />
        </div>
      );
    }
  
    return <VideoFeed />;
  };*/

  const AuthenticatedLayout = ({ children }) => (
    <div className="authenticated-container">
      <TopNavbar user={user} onLogout={logout} />
      <div className="main-content">
        {children}
      </div>
      {/*<BottomNavbar videoUrls={videos} />*/}
    </div>
  );

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <AuthenticatedLayout>
            <Component {...props} />
          </AuthenticatedLayout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="app">
        <div className="container">
          <Switch>
            <Route path="/login">
              <Login onLogin={login} />
            </Route>
            <Route path="/inscription">
              <Inscription onSignUp={login} />
            </Route>
            <Route path="/reset-password">
              <ForgotPassowrd />
            </Route>

            <PrivateRoute exact path="/" component={Home} />
            <PrivateRoute path="/friend-profile/:username" component={FriendProfile} />
            <PrivateRoute path="/friend" component={NotificationPage} />
            <PrivateRoute path="/create" component={Create} />
            <PrivateRoute path="/inbox" component={Inbox} />

            <PrivateRoute path="/profile">
              <Profile user={user} videos={videos} />
            </PrivateRoute>

            {/*<PrivateRoute
              path="/profile/:username"
              render={(props) => {
                const username = props.match.params.username;
                const user = videoUrls.find((video) => video.username === username);
                if (!username || !user) {
                  return <div>User not found</div>;
                }
                return (
                  <Profile
                    {...props}
                    user={user}
                    url={videoUrls}
                  />
                );
              }}
            />
            
            <PrivateRoute path="/profile">
              <Profile user={user} videos={videos} />
            </PrivateRoute>
            
            */}

          </Switch>
          {/*<BottomNavbar className="bottom-navbar" videoUrls={videoUrls} />*/}
          {isAuthenticated && <BottomNavbar className="bottom-navbar" />}
        </div>
      </div>
    </Router>
  );
}

export default App;