import React from 'react';
import './Message.css';

const Message = ({ sender, content, isSent }) => {
  return (
    <div className={`message ${isSent ? 'sent' : 'received'}`}>
      <div className="message-sender">{sender}</div>
      <div className="message-content">{content}</div>
    </div>
  );
};

export default Message;
