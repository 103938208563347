import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPen, faXmark } from '@fortawesome/free-solid-svg-icons';
import './Profile.css';
import axios from 'axios';

const Profile = ({ user }) => {
  const [activeTab, setActiveTab] = useState("Publications");
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchUserVideos = async () => {
      try {
        const response = await axios.get('http://localhost:3001/api/profile/videos', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        setVideos(response.data);
      } catch (error) {
        console.error('Error fetching user videos:', error);
      }
    };

    fetchUserVideos();
  }, []);

  return (
    <div className="profile-container">

      <div className="profile-header">
        <div className="profile-image">
          <FontAwesomeIcon icon={faUser} />
        </div>
        <div className="profile-info">
          <span className="username">@{user.username}</span>
          <div className="follow-stats">
            <span className="following">35 Following</span>
            <span className="followers">35 Followers</span>
            <span className="likes">202 Likes</span>
          </div>
        </div>
      </div>

      <div className="profile-actions">
        <button className="follow-button">Follow</button>
        <div className="edit-profile">
          <FontAwesomeIcon icon={faPen} className="edit-icon" />
        </div>
      </div>

      <div className="navigation-icons">
        <span
          onClick={() => setActiveTab("Publications")}
          className={activeTab === "Publications" ? "activeTab" : ""}
        >
          Publications
        </span>
        <span
          onClick={() => setActiveTab("Suggestions")}
          className={activeTab === "Suggestions" ? "activeTab" : ""}
        >
          Suggestions
        </span>
      </div>

      {activeTab === "Publications" && (
        <div className="user-posts">
          {videos.map((post) => (
            <div key={post.id} className="post">
              <video src={post.url} controls />
            </div>
          ))}
        </div>
      )}

      {activeTab === "Suggestions" && (
        <div className="suggestions">
          {
            videos.map((post) => (
              <div key={post.id} className='contents'>
                <div className="img">
                  <FontAwesomeIcon icon={faUser} className='fauser' />
                </div>
                <div className="names">
                  <p>{post.username}</p>
                  <span>Person you may know</span>
                </div>
                <div className="btn-follow">
                  <button>Follow</button>
                  <FontAwesomeIcon icon={faXmark} className='x' />
                </div>
              </div>
            ))
          }
        </div>
      )}

    </div>
  );
};

export default Profile;
