import React, { useState } from 'react';
import './Inbox.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import Message from './Message';


const Inbox = () => {
  const [messages, setMessages] = useState([
    { id: 1, sender: 'User1', content: 'How are you?', isSent: false },
    { id: 2, sender: 'You', content: 'Can we meet tomorrow?', isSent: true },
    { id: 3, sender: 'You', content: 'At 10 pm?', isSent: true },
    { id: 4, sender: 'User1', content: "I don't think so!", isSent: false },
  ]);
  const [newMessage, setNewMessage] = useState('');

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      setMessages([...messages, { id: messages.length + 1, sender: 'You', content: newMessage, isSent: true }]);
      setNewMessage('');
    }
  };

  return (
    <div className="inbox-container">
      <div className="inbox-content">
        {messages.map(message => (
          <Message key={message.id} sender={message.sender} content={message.content} isSent={message.isSent} />
        ))}
      </div>
      <div className="compose-message">
        <textarea
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Write a message..."
          rows="1"
        />
        <button onClick={handleSendMessage}><FontAwesomeIcon icon={faPaperPlane} /> Send</button>
      </div>
    </div>
  );
};

export default Inbox;

