/*import axios from 'axios';

const api = axios.create({
  baseURL: '/api', 
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('user');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default api;*/


// api.js

import axios from 'axios';

const API_URL = 'http://localhost:3001'; // Replace with your backend server URL

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const signup = (userData) => api.post('/auth/signup', userData);
export const login = (userData) => api.post('/auth/login', userData);
export const getUserProfile = (username, token) =>
  api.get(`/profile/${username}`, { headers: { Authorization: `Bearer ${token}` } });
export const getUserFeed = (token) =>
  api.get('/feed', { headers: { Authorization: `Bearer ${token}` } });

export default api;
