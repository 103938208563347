import React, { useState } from 'react';
import './NotificationPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';

const NotificationPage = () => {

  const [notifications, setNotifications] = useState([
    { id: 1, type: 'Like', content: 'User1 liked your post' },
    { id: 2, type: 'Follow', content: 'User2 started following you' },
    { id: 3, type: 'Like', content: 'User3 liked your post' },
    { id: 4, type: 'Follow', content: 'User4 started following you' },
  ]);

  const renderNotifications = () => {
    return notifications.map(notification => (
      <div key={notification.id} className="notifications">
        <div className="notification-type">{notification.type}</div>
        <div className="notification-content">{notification.content}</div>
      </div>
    ));
  };

  return (
    <div className='notification-container'>
      <div className="notification-tabs">
        <div className="notification-tab active">
          <FontAwesomeIcon icon={faBell} />
          <span>Notifications</span>
        </div>
      </div>
      <div className="notification-content">
        {renderNotifications()}
      </div>
    </div>
  );
}

export default NotificationPage;