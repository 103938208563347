import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPen, faXmark } from '@fortawesome/free-solid-svg-icons';
import './FriendProfile.css';

const FriendProfile = ({ url, user, video }) => {
  const [activeTab, setActiveTab] = useState('Publications');


  return (
    <div className='profile-container'>
      <div className='profile-header'>
        <div className='profile-image'>
          <FontAwesomeIcon icon={faUser} />
        </div>
        <div className='profile-info'>
          <span className='username'>@{video.username}</span>
          <div className='follow-stats'>
            <span className='following'>35 Followers</span>
            <span className='followers'>35 Followers</span>
            <span className='likes'>{ video.likes } Likes</span>
          </div>
        </div>
      </div>
      <div className='profile-actions'>
        <button className='follow-button'>Follow</button>
        <div className='edit-profile'>
          <FontAwesomeIcon icon={faPen} className='edit-icon' />
        </div>
      </div>
      <div className='navigation-icons'>
        <span
          onClick={() => setActiveTab('Publications')}
          className={activeTab === 'Publications' ? 'activeTab' : ''}
        >
          Publications
        </span>
        <span
          onClick={() => setActiveTab('Suggestions')}
          className={activeTab === 'Suggestions' ? 'activeTab' : ''}
        >
          Suggestions
        </span>
      </div>
      {activeTab === 'Publications' && (
        <div className='user-posts'>
          {url.map((post, index) => (
            <div key={index} className='post'>
              <video src={post.url} controls />
            </div>
          ))
          
          }
        </div>
      )}

      {activeTab === 'Suggestions' && user && (
        <div className='suggestions'>
          {url.map((post, index) => (
              <div key={index} className='contents'>
                <div className='img'>
                  <FontAwesomeIcon icon={faUser} className='fauser' />
                </div>
                <div className='names'>
                  <p>{post.name}</p>
                  <span>Person you may know</span>
                </div>
                <div className='btn-follow'>
                  <button>Follow</button>
                  <FontAwesomeIcon icon={faXmark} className='x' />
                </div>
              </div>
          ))}
        </div>
      )}

    </div>
  );
};

export default FriendProfile;

/*import React from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const FriendProfile = ({ video }) => {

  const { id } = useParams()

  return (
    <div className='profile-container'>
      <h1>FriendProfile - { video.username}</h1>
    </div>
  )
}

export default FriendProfile*/
